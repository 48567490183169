<script setup lang="ts">
import type { ModelRef } from 'vue'
import {
  ChevronDownIcon,
  ListFilterIcon,
} from 'lucide-vue-next'
import { CollectStatus } from '~/types/graphql-backend-types/gql-types'

const status: ModelRef<CollectStatus[]> = defineModel() as ModelRef<CollectStatus[]>
const { t } = useI18n()

const statuses: { value: CollectStatus, label: string }[] = [
  { value: CollectStatus.RequestSent, label: t('global.request_sent') },
  { value: CollectStatus.Confirmed, label: t('collect.status_confirmed') },
  { value: CollectStatus.InTreatment, label: t('collect.status_inTreatment') },
  { value: CollectStatus.AdminInProgress, label: t('collect.status_adminInProgress') },
  { value: CollectStatus.HasIncident, label: t('collect.status_hasIncident') },
  { value: CollectStatus.Finished, label: t('collect.status_finished') },
  { value: CollectStatus.Cancelled, label: t('collect.status_cancelled') },
  { value: CollectStatus.WpCancel, label: t('collect.status_wpCancel') },
]

function addStatusToFilteredStatuses(s: CollectStatus) {
  if (status.value.includes(s)) {
    status.value = status.value.filter(st => st !== s)
    return
  }
  status.value.push(s)
}
</script>

<template>
  <EDropdown
    v-model="status"
    :button-label="t('global.status')"
    :btn-icon="ListFilterIcon"
    :suffix-btn-icon="ChevronDownIcon"
    :multiple="true"
    :options="statuses"
    @change="addStatusToFilteredStatuses($event as CollectStatus)"
  />
</template>
