<script setup lang="ts">
import type { CollectGraphql } from '~/types/graphql-backend-types/gql-types'
import { TriangleAlert } from 'lucide-vue-next'

const props = defineProps({
  collect: {
    type: Object as PropType<CollectGraphql>,
    default: () => {},
  },
  id: {
    type: String,
    required: true,
  },
})

const { t } = useI18n()
const { cancelCollect, loadCollects } = useCollectsStore()
const { addToast } = useToast()
const open = defineModel<boolean>() as Ref<boolean>

const { collect, id } = toRefs(props)

async function deleteCollect(id: string) {
  const dialog = document.getElementById(props.id) as HTMLDialogElement

  try {
    await cancelCollect(id)
    addToast(t('global.success'), { type: 'success', message: t('success.collect_cancelled') })
    loadCollects()
  }
  catch (error) {
    addToast(t('global.error'), { type: 'error', message: error as string })
    open.value = false
    dialog.close()
  }
  open.value = false
  dialog.close()
}
</script>

<template>
  <WModal :id="id" v-model="open">
    <template #title>
      <div class="flex items-center gap-2 mb-2">
        <div class="bg-red-200 p-0.5 rounded">
          <TriangleAlert color="#EF4444" />
        </div>
        <p class="text-xl font-semibold">
          {{ t("collect.cancel") }}
        </p>
      </div>
    </template>
    <template #default>
      <p>
        {{ t("global.are_you_sure_to_delete") }}
      </p>
    </template>
    <template #footer>
      <EButton
        class="btn-error text-error-content"
        :label="t('collect.cancel')"
        @click="deleteCollect(collect.id)"
      />
    </template>
  </WModal>
</template>
