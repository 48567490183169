<script setup lang="ts">
import {
  CircleAlertIcon,
  CircleCheckIcon,
  CircleXIcon,
  InfoIcon,
  XIcon,
} from 'lucide-vue-next'

const { toasts, removeToast } = useToast()

const colorByType = {
  success: 'bg-[#2ecc71]',
  error: 'bg-[#e74c3c]',
  warning: 'bg-[#f39c12]',
  info: 'bg-[#3498db]',
}
</script>

<template>
  <Teleport to="body">
    <div class="fixed top-[20px] right-[20px] z-[99999] isolate">
      <transition-group name="toast">
        <div
          v-for="toast in toasts"
          :key="toast.id"
          class="relative px-4 py-2 min-h-16 mb-2 min-w-72 bg-base-100 text-base-content rounded-lg flex justify-between items-center overflow-hidden shadow-md"
          :class="toast.type"
        >
          <div class="flex gap-3 grow justify-between items-center">
            <div class="flex gap-3 items-center">
              <component :is="CircleXIcon" v-if="toast.type === 'error'" color="#e74c3c" :size="18" />
              <component :is="CircleCheckIcon" v-if="toast.type === 'success'" color="#2ecc71" :size="18" />
              <component :is="CircleAlertIcon" v-if="toast.type === 'warning'" color="#f39c12" :size="18" />
              <component :is="InfoIcon" v-if="toast.type === 'info'" color="#3498db" :size="18" />
              <div class="flex flex-col">
                {{ toast.title }}
                <div class="flex items-center gap-2">
                  <span class="text-sm text-base-content/80">{{ toast.message }}</span>
                  <component :is="toast.icon" v-if="toast.icon" :size="18" />
                </div>
              </div>
            </div>
            <component :is="XIcon" :size="14" class="cursor-pointer" @click="removeToast(toast.id)" />
          </div>
          <div
            class="absolute bottom-0 left-0 h-1"
            :class="colorByType[toast.type as keyof typeof colorByType]"
            :style="{ width: `${toast.progress}%` }"
          />
        </div>
      </transition-group>
    </div>
  </Teleport>
</template>

<style scoped>
.toast-enter-active,
.toast-leave-active {
  transition: all 0.3s ease;
}

.toast-enter-from,
.toast-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>
