<script setup lang="ts">
defineProps({
  label: {
    type: String,
    default: null,
  },
  id: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  checked: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits<{
  (e: 'change', value: any): void
}>()

const model = defineModel({ default: false })

function handleChange(event: Event) {
  emit('change', event)
}
</script>

<template>
  <label class="flex items-center">
    <input
      :id="id"
      v-model="model"
      type="checkbox"
      value=""
      :checked="model || checked"
      :disabled="disabled"
      class="w-4 h-4 text-primary bg-base-100 border-neutral rounded border focus:ring-primary/50 focus:ring-2"
      @change.stop="handleChange"
    >
    <span class="ml-2 text-sm font-medium text-base-content">{{ label }}</span>
  </label>
</template>

<style scoped>
[type='checkbox']:checked {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 12'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M1 5.917 5.724 10.5 15 1.5'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 0.55em 0.55em;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  background-color: #5430d9;
  background-position: 50%;
  background-repeat: no-repeat;
  border-color: transparent;
}

[type='checkbox'] {
  --tw-shadow: 0 0 #0000;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-origin: border-box;
  border-width: 1px;
  color: #5430d9;
  display: inline-block;
  flex-shrink: 0;
  height: 1rem;
  padding: 0;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
  width: 1rem;
}
</style>
