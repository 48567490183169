import gql from 'graphql-tag'

export const ADMIN_FIELDS_FRAGMENT = gql`
  fragment AdminFields on CollectGraphql {
    isInvoiceable {
      errors
      alreadyInvoiced
      result
    }
  }
`
