<script setup lang="ts">
import type { PropType } from 'vue'
// Causing a vee-validate warning if no parent form is found
import { useIsFormTouched } from 'vee-validate'

import {
  computed,
  nextTick,
  onMounted,
  ref,
  toRefs,
} from 'vue'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps({
  options: {
    type: Array as PropType<any[]>,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    default: '',
  },
  prefixIcon: {
    type: Function,
  },
  label: {
    type: String,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  keyForLabel: {
    type: String,
    default: 'label',
  },
  outerClasses: {
    type: String,
    default: '',
  },
  keyForValue: {
    type: String,
    default: 'value',
  },
  showDocument: {
    type: Boolean,
    default: false,
  },
  error: {
    type: String,
    default: null,
  },
  allowDisableField: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits(['change', 'input'])
const model = defineModel()
const isFormTouched = useIsFormTouched()

const { keyForLabel, keyForValue, id, disabled, options, error, allowDisableField } = toRefs(props)
const selectedOption = ref<any>(null)
const selectRef = ref<HTMLSelectElement | null>(null)

const containerClasses = computed(() => [
  'relative flex items-center rounded-lg bg-base-100',
])

const selectClasses = computed(() => [
  'w-full px-3 py-1 h-8 text-sm text-base-content rounded-lg',
  'bg-transparent border border-neutral focus:outline-none focus:ring-0',
  'appearance-none',
  { 'cursor-not-allowed': disabled.value },
  { 'text-base-content/60': showPlaceholder.value },
  {
    '!border-red-500': isFormTouched.value && error.value,
    '!border-green-500': error.value !== null && model.value,
    'opacity-60 cursor-not-allowed !bg-base-200': disabled.value && allowDisableField.value,
  },
])

const selectWrapperClasses = computed(() => [
  'relative w-full',
])

const showPlaceholder = computed(() => {
  return !model.value && typeof model.value !== 'number'
})

function getOptionLabel(option: any): string {
  if (typeof option === 'object' && option !== null) {
    return option[keyForLabel.value]
  }
  const opt = options.value.find(opt => getOptionValue(opt) === option)
  if (opt)
    return opt[keyForLabel.value] || String(opt)
  return String(option)
}

function getOptionValue(option: any): string {
  if (typeof option === 'object' && option !== null) {
    return option[keyForValue.value]
  }
  return String(option)
}

function isOptionDisabled(option: any): boolean {
  return allowDisableField.value && option?.disabled
}

function handleChange(event: Event) {
  const select = event.target as HTMLSelectElement
  const value = select.value

  // Handle empty selection (placeholder)
  if (value === '') {
    model.value = undefined
    selectedOption.value = null
    emit('change', undefined)
    emit('input', undefined)
    return
  }

  const option = options.value.find(opt => getOptionValue(opt) == value)
  if (option) {
    model.value = value
    selectedOption.value = option
    emit('change', value)
    emit('input', value)
  }
}

onMounted(() => {
  nextTick(() => {
    if (model.value !== undefined) {
      selectedOption.value = options.value.find(option => getOptionValue(option) == model.value)
      emit('input', model.value)
    }
  })
})
</script>

<template>
  <label class="form-control w-full" :class="[outerClasses]">
    <div v-if="label" :for="id" class="label">
      <span class="label-text">{{ label }}</span>
    </div>

    <div :class="containerClasses">
      <div :class="selectWrapperClasses" class="custom-select-wrapper">
        <select
          :id="id"
          ref="selectRef"
          class="select select-sm select-bordered"
          :value="model"
          :disabled="disabled"
          :class="selectClasses"
          v-bind="$attrs"
          @change="handleChange"
        >
          <option v-if="placeholder" value="" :selected="showPlaceholder" hidden>
            {{ placeholder }}
          </option>

          <option
            v-for="option in options"
            :key="getOptionValue(option)"
            :value="getOptionValue(option)"
            :disabled="isOptionDisabled(option)"
          >
            {{ getOptionLabel(option) }}
          </option>
        </select>
      </div>
    </div>

    <!-- Error message -->
    <div v-if="isFormTouched && error" class="label">
      <span class="label-text-alt text-error">{{ error }}</span>
    </div>
  </label>
</template>

<style scoped>
:deep(select) {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 30px;
}

:deep(select.has-prefix-icon),
:deep(select.has-document-icon) {
  padding-left: 35px;
}
</style>
