<script setup>
import { computed } from 'vue'

const props = defineProps({
  color: {
    type: String,
    validator: (value) => {
      return ['primary', 'secondary', 'neutral', 'accent', 'success', 'error', 'info', 'warning'].includes(value)
    },
  },
})

const colorMap = {
  primary: {
    bg: 'bg-primary',
    text: 'text-primary-content',
  },
  secondary: {
    bg: 'bg-secondary',
    text: 'text-secondary-content',
  },
  neutral: {
    bg: 'bg-neutral',
    text: 'text-neutral-content',
  },
  accent: {
    bg: 'bg-accent',
    text: 'text-accent-content',
  },
  success: {
    bg: 'bg-success',
    text: 'text-success-content',
  },
  error: {
    bg: 'bg-error',
    text: 'text-error-content',
  },
  info: {
    bg: 'bg-info',
    text: 'text-info-content',
  },
  warning: {
    bg: 'bg-warning',
    text: 'text-warning-content',
  },
}

const backgroundColor = computed(() => {
  return props.color ? colorMap[props.color].bg : ''
})

const textColor = computed(() => {
  return props.color ? colorMap[props.color].text : ''
})
</script>

<template>
  <span
    class="inline-flex items-center justify-center px-2.5 py-0.5 rounded-full text-xs font-medium w-fit h-fit whitespace-nowrap" :class="[
      backgroundColor,
      textColor,
      $attrs.class,
    ]"
  >
    <slot />
  </span>
</template>
