<script lang="ts" setup>
const props = defineProps({
  icon: Function,
  size: {
    default: 18,
    type: Number,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: null,
  },
  btnClass: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['click'])

const attrs = useAttrs()
</script>

<template>
  <button v-bind="attrs" class="btn btn-sm" :class="btnClass" :disabled="disabled || loading" @click.stop="emit('click')">
    <div v-if="loading" class="loading loading-spinner" />
    <component :is="props.icon" v-if="props.icon" :size="size" />
    {{ label }}
    <slot />
  </button>
</template>
