import type { Maybe } from '~/types/graphql-backend-types/gql-types'

export function getPricesWithoutVTA(price: number, quantity: Maybe<number> | undefined): number {
  if (quantity) {
    const priceWithoutVAT = price * quantity
    if (Number.isNaN(priceWithoutVAT))
      return 0
    return priceWithoutVAT
  }
  return 0
}

export function getPricesWithVAT(price: number, quantity: number, vta: number): number {
  // Convert VAT percentage to decimal format (e.g., 20 becomes 1.20)
  const vatMultiplier = 1 + (vta / 100)

  // Calculate price with VAT
  const priceWithVAT = price * quantity * vatMultiplier

  // Check for invalid results
  if (!Number.isFinite(priceWithVAT)) {
    return 0
  }

  // Round to 2 decimal places and return as number
  return Number(priceWithVAT.toFixed(2))
}

export function getPricesWithVTAAndDiscount(price: number, quantity: number, vta: number, discount: number) {
  const priceWithVAT = price * quantity * (Number.parseFloat(`1.${vta}`))
  const priceWithVATAndDiscount = priceWithVAT * (Number.parseFloat(`1.${discount}`))
  if (Number.isNaN(priceWithVATAndDiscount))
    return 0
  return priceWithVATAndDiscount
}

export function calculatePercentageDifference(value1: number, value2: number): number {
  if (value1 === value2)
    return 0
  return Number((((value2 - value1) / value1) * 100).toFixed(2))
}
