<script setup lang="ts">
import { UserCircleIcon } from 'lucide-vue-next'

const model = defineModel({ default: [] })
const { t } = useI18n()

const { getClientProducers } = storeToRefs(useUsersStore())

const clientOptions = computed(() => {
  if (getClientProducers.value.length > 0) {
    return getClientProducers.value.map(producer => ({ value: producer.id, label: producer.name }))
  }
  return []
})
</script>

<template>
  <EDropdown
    v-model="model"
    :button-label="t('global.sort_by_client')"
    :btn-icon="UserCircleIcon"
    :options="clientOptions"
    :multiple="true"
  />
</template>
