import type {
  DeclareIncidentGraphqlInput,
  IncidentFiltersGraphql,
  IncidentGraphql,
  Mutation,
  UpdateIncidentGraphqlInput,
} from '~/types/graphql-backend-types/gql-types'
import { useMutation } from '@vue/apollo-composable'
import {
  DECLARE_INCIDENT_ON_COLLECT,
  DELETE_INCIDENT,
  GET_INCIDENTS,
  UPDATE_INCIDENT_ON_COLLECT,
} from '~/queries/incidents'

export const useIncidentStore = defineStore('incidents', () => {
  const { query, mutate } = useGqlMikro()

  const page = ref<number>(1)
  const offset = computed(() => (page.value - 1) * 10)
  const incidentCount = ref(0)
  const incidents = ref<IncidentGraphql[]>([])
  const incident = ref<IncidentGraphql>()
  const getIncidents = computed(() => incidents.value)

  function getIncidentById(id: string) {
    return incidents.value.find(i => i.id === id)
  }

  const loadIncidents = async (f: IncidentFiltersGraphql = {}) => {
    const { clientIds, ids } = f
    const filters: IncidentFiltersGraphql = {}
    if (clientIds && !clientIds.includes('0'))
      filters.clientIds = clientIds
    if (ids)
      filters.ids = ids
    const { data, errors } = await query({
      query: GET_INCIDENTS,
      variables: {
        pagination: {
          limit: 10,
          offset: offset.value,
        },
        filters,
      },
    })

    if (errors) {
      throw new Error(errors[0].message)
    }

    incidentCount.value = data.incidents.count
    incidents.value = JSON.parse(JSON.stringify(data.incidents.collection)) as IncidentGraphql[]
  }

  const loadIncident = async (f: IncidentFiltersGraphql) => {
    const { ids } = f
    const filters: IncidentFiltersGraphql = {}
    if (ids)
      filters.ids = ids
    const { data, errors } = await query({
      query: GET_INCIDENTS,
      variables: {
        pagination: {
          limit: 1,
          offset: 0,
        },
        filters,
      },
    })

    if (errors) {
      throw new Error(errors[0].message)
    }

    incident.value = data.incidents.collection[0] as IncidentGraphql
  }

  // HASURA
  const deleteIncident = async (id: string) => {
    const { mutate } = useMutation(DELETE_INCIDENT)
    await mutate({ id })
    incidents.value = incidents.value.filter(item => item.id !== id)
  }

  const updateIncidentOnCollect = async (incident: UpdateIncidentGraphqlInput) => {
    const { data, errors } = await mutate({
      mutation: UPDATE_INCIDENT_ON_COLLECT,
      variables: {
        input: incident,
      },
    })

    if (errors) {
      throw new Error(errors[0].message)
    }

    const incidentIndex = incidents.value.findIndex(i => i.id === incident.incidentId)
    incidents.value[incidentIndex] = data!.updateIncident
  }

  const declareIncidentOnCollect = async (incident: DeclareIncidentGraphqlInput): Promise<Mutation['declareIncidentOnCollect']> => {
    const { data, errors } = await mutate({
      mutation: DECLARE_INCIDENT_ON_COLLECT,
      variables: {
        input: incident,
      },
    })

    if (errors) {
      throw new Error(errors[0].message)
    }

    incidents.value = [...incidents.value, data!.declareIncidentOnCollect]
    return data?.declareIncidentOnCollect as IncidentGraphql
  }

  watch(page, async () => {
    await loadIncidents()
  })

  return {
    incidents,
    incident,
    getIncidents,
    incidentCount,
    page,
    getIncidentById,
    loadIncidents,
    loadIncident,
    deleteIncident,
    declareIncidentOnCollect,
    updateIncidentOnCollect,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useIncidentStore, import.meta.hot))
}
