<script lang="ts" setup>
import { RotateCcwIcon } from 'lucide-vue-next'

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
})
const emit = defineEmits(['close', 'loadLatestVersion'])
const { t } = useI18n()

const { id } = toRefs(props)

function closeModal() {
  const dialog = document.getElementById(id.value) as HTMLDialogElement
  dialog.close()
}
function loadLatestVersion() {
  emit('loadLatestVersion')
  closeModal()
}
</script>

<template>
  <WModal
    :id="id"
  >
    <template #title>
      <div class="flex flex-col gap-4 md:gap-8">
        <div class="flex flex-col gap-1">
          <h2 class="text-xl font-semibold">
            {{ $t('global.new_version') }}
          </h2>
        </div>
      </div>
    </template>

    <template #default>
      <div class="grid grid-cols-1 items-center gap-2">
        <p>{{ t("global.click_button_reload") }}</p>
      </div>
    </template>
    <template #footer>
      <EButton
        class="btn-primary"
        :icon="RotateCcwIcon"
        :label="t('global.reload')"
        @click="loadLatestVersion"
      />
    </template>
  </WModal>
</template>
