<script setup lang="ts">
import { ChevronLeft, ChevronRight } from 'lucide-vue-next'
import { computed } from 'vue'

const props = defineProps({
  modelValue: {
    type: Number,
    required: true,
    default: 1,
  },
  numberPage: {
    type: Number,
    required: true,
    default: 1,
  },
})

const emit = defineEmits<{
  (e: 'update:modelValue', val: number): void
}>()

const { t } = useI18n()

function goToPagination(page: number | string) {
  if (page === '...')
    return
  if (page !== props.modelValue)
    emit('update:modelValue', page as number)
}

const paginationList = computed(() => {
  const pagination = []
  const numberPage = props.numberPage
  const modelValue = props.modelValue

  if (numberPage <= 7) {
    for (let i = 1; i <= numberPage; i++)
      pagination.push(i)
  }
  else {
    if (modelValue <= 4) {
      for (let i = 1; i <= 5; i++)
        pagination.push(i)
      pagination.push('...')
      pagination.push(numberPage)
    }
    else if (modelValue >= numberPage - 3) {
      pagination.push(1)
      pagination.push('...')
      for (let i = numberPage - 4; i <= numberPage; i++)
        pagination.push(i)
    }
    else {
      pagination.push(1)
      pagination.push('...')
      for (let i = modelValue - 1; i <= modelValue + 1; i++)
        pagination.push(i)
      pagination.push('...')
      pagination.push(numberPage)
    }
  }
  return pagination
})

function getPageNumbers() {
  const pages = []
  const showEllipsis = props.numberPage > 7

  if (!showEllipsis) {
    return Array.from({ length: props.numberPage }, (_, i) => i + 1)
  }

  // Always show first page
  pages.push(1)

  if (props.modelValue > 3) {
    pages.push('...')
  }

  // Calculate middle pages
  let start = Math.max(2, props.modelValue - 1)
  let end = Math.min(props.numberPage - 1, props.modelValue + 1)

  if (props.modelValue <= 3) {
    end = Math.min(props.numberPage - 1, 5)
  }

  if (props.modelValue >= props.numberPage - 2) {
    start = Math.max(2, props.numberPage - 4)
  }

  for (let i = start; i <= end; i++) {
    pages.push(i)
  }

  if (props.modelValue < props.numberPage - 2) {
    pages.push('...')
  }

  // Always show last page
  if (props.numberPage !== 1) {
    pages.push(props.numberPage)
  }

  return pages
}

function updatePagination(action: string) {
  switch (action) {
    case 'previous':
      if (props.modelValue !== 1)
        emit('update:modelValue', props.modelValue - 1)
      break
    case 'next':
      if (props.modelValue !== props.numberPage)
        emit('update:modelValue', props.modelValue + 1)
      break
    default:
      break
  }
}
</script>

<template>
  <nav v-if="paginationList.length > 0" :aria-label="t('global.table.nav')" class="flex justify-center">
    <div class="flex items-center space-x-1">
      <!-- Previous button -->
      <EButton
        class="btn-square"
        :class="modelValue === 1 ? 'opacity-50 cursor-not-allowed' : ''"
        :disabled="modelValue === 1"
        @click="updatePagination('previous')"
      >
        <ChevronLeft :size="16" />
      </EButton>

      <!-- Page numbers -->
      <template v-for="index in getPageNumbers()" :key="index">
        <EButton
          class="btn-square"
          :class="{
            'bg-primary text-primary-content hover:bg-primary': modelValue === index,
            'opacity-50 cursor-not-allowed': index === '...',
          }"
          :disabled="index === '...'"
          @click="goToPagination(index)"
        >
          {{ index }}
        </EButton>
      </template>

      <!-- Next button -->
      <EButton
        class="btn-square"
        :class="modelValue === numberPage || numberPage === 0 ? 'opacity-50 cursor-not-allowed' : ''"
        :disabled="modelValue === numberPage || numberPage === 0"
        @click="updatePagination('next')"
      >
        <ChevronRight :size="16" />
      </EButton>
    </div>
  </nav>
</template>
