// vClickOutside.ts
import type { Directive, DirectiveBinding } from 'vue'

interface ClickOutsideElement extends HTMLElement {
  _clickOutside?: {
    handler: (event: MouseEvent) => void
    exclude?: () => HTMLElement[]
  }
}

interface ClickOutsideBinding extends DirectiveBinding {
  value: (event: MouseEvent) => void
  modifiers: {
    exclude?: boolean
  }
}

const clickOutside: Directive = {
  mounted(el: ClickOutsideElement, binding: ClickOutsideBinding) {
    const handler = (event: MouseEvent) => {
      const target = event.target as Node

      // Check if click was outside the el and its children
      if (!(el === target || el.contains(target))) {
        // Check for any excluded elements
        if (binding.modifiers.exclude) {
          const excludedElements = el._clickOutside?.exclude?.() || []
          const isExcluded = excludedElements.some(excludedEl =>
            excludedEl === target || excludedEl.contains(target),
          )
          if (isExcluded)
            return
        }

        // Call the provided handler
        binding.value(event)
      }
    }

    // Save the handler on the element for cleanup
    el._clickOutside = {
      handler,
      exclude: binding.modifiers.exclude
        ? () => [] // Override this when using the directive
        : undefined,
    }

    document.addEventListener('click', handler, true)
  },

  beforeUnmount(el: ClickOutsideElement) {
    if (el._clickOutside) {
      document.removeEventListener('click', el._clickOutside.handler, true)
      delete el._clickOutside
    }
  },
}

// Register the directive
export default clickOutside

// Usage example:
/*
import { createApp } from 'vue'
import vClickOutside from './vClickOutside'

const app = createApp({})
app.directive('click-outside', vClickOutside)
*/

// In your components:
/*
<template>
  <div v-click-outside="onClickOutside">
    <!-- Your content -->
  </div>
</template>

// With excluded elements:
<template>
  <div
    v-click-outside.exclude="onClickOutside"
    :_clickOutside="{ exclude: () => [excludedElement] }"
  >
    <!-- Your content -->
  </div>
</template>

<script setup lang="ts">
const onClickOutside = () => {
  // Handle click outside
}
</script>
*/
