import {
  ApolloClient,
  concat,
  HttpLink,
  InMemoryCache,
} from '@apollo/client/core'
import { getBackEndURL } from '~/config'
import { fragmentRegistry, registerFragment } from '~/fragments'
import { fb_auth } from '~/services/firebase'

registerFragment()

const AUTH_TOKEN = 'apollo-token'
const token = ref('')

export async function getToken(): Promise<string | undefined> {
  const user: any = fb_auth.currentUser
  token.value = await user?.getIdToken()
  if (token.value)
    localStorage.setItem(AUTH_TOKEN, token.value)
  return token.value
}

const httpLink = new HttpLink({ uri: `${getBackEndURL()}/graphql` })

const authMiddleware: any = async (operation: any, forward: any) => {
  const { overlayedUserToken } = storeToRefs(useAdminTokenStore())
  // add the authorization to the headers
  await getToken()
  const t = overlayedUserToken.value ? overlayedUserToken.value : token.value
  operation.setContext({
    headers: {
      authorization: t ? `Bearer ${t}` : 'token bad',
    },
  })
  return forward(operation)
}

const apolloClientMicroOrm = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache({ fragments: fragmentRegistry }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
})

export default apolloClientMicroOrm
