<script setup lang="ts">
import type { CollectGraphql } from '~/types/graphql-backend-types/gql-types'
import { Calendar, InfoIcon, MessageSquareMore } from 'lucide-vue-next'

const { filters, page, collectsLoading, isEmptyFilters, getCollects, collectsCount } = storeToRefs(useCollectsStore())
const { loadCollects } = useCollectsStore()

const router = useRouter()
const { t } = useI18n()

const cancelCollectModal = ref<HTMLDialogElement>() as Ref<HTMLDialogElement>
const collectToCancel = ref<CollectGraphql>()

function newCollect() {
  router.push('/collects/new')
}

function openCancelCollect(collect: CollectGraphql) {
  collectToCancel.value = collect
  cancelCollectModal.value.showModal()
}

onMounted(async () => {
  await loadCollects()
  cancelCollectModal.value = document.getElementById('cancel_collect_modal') as HTMLDialogElement
})
</script>

<template>
  <div class="flex flex-col gap-4 p-4 pb-0 grow overflow-auto bg-inherit">
    <div v-if="getCollects" class="flex items-center justify-between gap-2">
      <div class="flex gap-1">
        <RecollectionSortingPopOver v-model="filters.sort" />
        <RecollectionStatusPopOver v-model="filters.statuses" />
      </div>

      <EButton
        id="create-collect-button"
        class="btn-primary"
        :label="t('global.new_collect')"
        :icon="Calendar"
        @click="newCollect()"
      />
    </div>
    <template v-if="!collectsLoading">
      <div v-if="getCollects?.length > 0" id="collects-producer-table" class="flex flex-col gap-4 overflow-auto grow p-1">
        <template v-for="collect in getCollects" :key="collect.id">
          <RecollectionMultiMaterialCard :collect="collect" @open-and-cancel-collect-modal="openCancelCollect($event)" />
        </template>
      </div>
      <template v-else>
        <div v-if="isEmptyFilters" class="flex grow flex-col pt-10">
          <div class="flex gap-4">
            <div class="grow">
              <div role="alert" class="alert alert-warning">
                <div class="flex flex-col gap-4">
                  <div class="flex gap-4">
                    <InfoIcon :size="24" />
                    <span>{{ t("global.no_services_for_the_moment") }}</span>
                  </div>
                  <div class="flex justify-end">
                    <EButton
                      id="schedule-collect-button"
                      class="btn-alert w-fit"
                      :label="t('global.schedule_a_service')"
                      :icon="Calendar"
                      @click="newCollect()"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="grow">
              <div role="alert" class="alert bg-base-100">
                <div class="flex flex-col gap-4">
                  <div class="flex gap-4">
                    <MessageSquareMore :size="24" />
                    <span>{{ t("global.problem_or_difficulty_contact_us") }}</span>
                  </div>
                  <div class="flex justify-end">
                    <a href="https://calendly.com/laure-wastextech " target="_blank" class="btn btn-sm btn-secondary">
                      <p>{{ t("global.contact_us") }}</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="grow">
          <div role="alert" class="alert alert-warning">
            <InfoIcon :size="24" />
            <span>{{ t("collect.noCollectsForFilters") }}</span>
          </div>
        </div>
      </template>
    </template>

    <div v-else class="flex grow items-center justify-center">
      <Loader class="mb-2 h-8 w-8 text-gray-500" />
    </div>

    <EPagination v-model="page" :number-page="Math.ceil(collectsCount / 10)" />
  </div>

  <CancelCollectModal id="cancel_collect_modal" :collect="collectToCancel" />
</template>
