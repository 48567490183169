<script setup lang="ts">
import type { PropType } from 'vue'
import type { IncidentGraphql, StoredDocumentGraphql } from '~/types/graphql-backend-types/gql-types'
import { CalendarRange, TriangleAlert } from 'lucide-vue-next'

const props = defineProps({
  incident: {
    type: Object as PropType<IncidentGraphql>,
    required: true,
  },
})

const emit = defineEmits<{
  click: [value: object]
  openModal: [value: StoredDocumentGraphql]
}>()

const { t } = useI18n()
const { incident } = toRefs(props)
const isExpanded = ref<boolean>(false)

function toggleExpandCard() {
  isExpanded.value = !isExpanded.value
}

function openModal(file: StoredDocumentGraphql) {
  emit('openModal', file)
}
</script>

<template>
  <div
    class="flex flex-row cursor-pointer items-start gap-4 border border-neutral rounded-lg bg-base-100 p-4 lg:justify-between"
    @click.stop.prevent="toggleExpandCard"
  >
    <div class="rounded-lg bg-[#FFF1F0] p-2.5">
      <TriangleAlert color="#CF1322" :size="24" />
    </div>
    <div class="w-full flex flex-col gap-2">
      <p class="font-semibold">
        {{ t("global.incident_received") }}
      </p>
      <p class="text-sm text-base-content/90">
        {{ t(`global.${incident.type}`) }}
      </p>
      <div class="flex flex-row items-center gap-4 text-base-content/80">
        <!-- <span>{{ incident.type_id }} </span> -->
        <span class="flex flex-row items-center gap-1 text-sm text-base-content/80">
          <CalendarRange :size="18" />
          {{
            new Date(incident.createdAt).toLocaleDateString('fr-FR', {
              day: "numeric",
              month: "numeric",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })
          }}
        </span>
      </div>
      <div class="flex items-start justify-between">
        <p
          class="line-clamp-2 text-sm text-base-content/80"
          :class="{ 'line-clamp-2': !isExpanded, 'line-clamp-none': isExpanded }"
        >
          {{ incident.comment }}
        </p>
      </div>
      <div v-if="incident.documents" class="flex items-center gap-2">
        <div v-for="picture in incident.documents.collection" :key="picture?.id">
          <ImageWrapper :src="picture!.signedUrl" class="mt-4" @click.stop.prevent="openModal(picture as StoredDocumentGraphql)" />
        </div>
      </div>
      <div class="w-full flex items-center justify-between gap-2">
        <Badge v-if="incident.penaltyAmount" color="error">
          {{ t("incident.penalty", { amount: incident.penaltyAmount }) }}
        </Badge>
      </div>
    </div>
  </div>
</template>
