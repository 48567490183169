import type { CollectGraphql, IncidentGraphql, RateCollectNpsInputGraphql } from '~/types/graphql-backend-types/gql-types'
import { COLLECTS_QUERY_SINGLE, RATE_COLLECT_NPS } from '~/queries/collects'
import { isObjectEmpty } from '~/utils/object'
import { getPricesWithoutVTA } from '~/utils/prices'

export const useCollectStore = defineStore('collect', () => {
  const collect = ref<CollectGraphql>({} as CollectGraphql)
  const collectLoading = ref<boolean>(false)
  const { isAdmin } = storeToRefs(useUsersStore())

  const incidents = computed(() => {
    if (isObjectEmpty(collect.value))
      return []
    return collect.value.incidents.collection as IncidentGraphql[]
  })

  const incidentsPenalty = computed(() => {
    let total = 0
    incidents.value.forEach((incident) => {
      if (incident)
        total += incident.penaltyAmount ? incident.penaltyAmount : 0
    })
    return total
  })

  const VAT = computed(() => {
    if (isObjectEmpty(collect.value))
      return 0
    return (
      (collect.value.collectedContainers.collection
        .map((item, index: number) => {
          return getPricesWithoutVTA(
            item?.material?.activePricing?.price as number,
            collect.value.collectedContainers.collection[index]?.quantity,
          )
        })
        .reduce((a: number, b: number) => a + b, 0)
        * (collect.value.collectedContainers.collection[0]!.material?.activePricing?.vat ?? 0))
      / 100
      + (collect.value.transportPrice! * 20) / 100
      + (incidentsPenalty.value * 20) / 100
    )
  })

  const totalPriceMaterialsWithoutVAT = computed(() => {
    if (isObjectEmpty(collect.value))
      return 0
    let total = 0
    collect.value.collectedContainers.collection.forEach((container) => {
      if (container) {
        total += (container?.material?.activePricing?.price ?? 0) * (container?.quantity ?? 0)
      }
    })
    return total
  })

  const totalPriceMaterialsWithVAT = computed(() => {
    if (isObjectEmpty(collect.value))
      return 0
    return (
      totalPriceMaterialsWithoutVAT.value
      + (totalPriceMaterialsWithoutVAT.value
        * collect.value.collectedContainers.collection[0]?.material?.activePricing?.vat
        ? collect.value.collectedContainers.collection[0]?.material?.activePricing?.vat
        : 0)
      / 100
    )
  })

  const pricingOfMaterials = computed(() => {
    if (isObjectEmpty(collect.value))
      return 0
    return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(
      collect.value.collectedContainers.collection[0]?.material?.activePricing?.price as number,
    )
  })

  const transportPrice = computed(() => {
    return collect.value?.transportPrice
  })

  const totalPriceIsSold = computed(() => {
    if (isObjectEmpty(collect.value))
      return 0
    return totalPriceMaterialsWithoutVAT.value + (transportPrice.value ?? 0) + incidentsPenalty.value + VAT.value
  })

  const totalPriceNotSold = computed(() => {
    if (isObjectEmpty(collect.value))
      return 0
    return totalPriceMaterialsWithVAT.value - (((transportPrice.value ?? 0) * 20) / 100 + (incidentsPenalty.value * 20) / 100)
  })

  async function loadSingleCollect(id: string) {
    if (!id)
      return

    const { query } = useGqlMikro()

    collectLoading.value = true
    const { data } = await query({ query: COLLECTS_QUERY_SINGLE, variables: {
      pagination: {},
      filters: { ids: [id] },
      isAdmin: isAdmin.value,
    } })
    collectLoading.value = false
    collect.value = JSON.parse(JSON.stringify(data.collects.collection[0]))
    collect.value.startsAt = new Date(collect.value.startsAt).toISOString()
    return collect.value
  }

  function addDocumentToCollectedContainer(document: any, collectedContainerId: number) {
    const collectedContainer = collect.value.collectedContainers.collection.find(item => Number(item?.id) === collectedContainerId)
    if (!collectedContainer)
      return
    collectedContainer?.documents.collection.push(document)
  }

  function removeDocumentFromCollectedContainer(documentId: string, collectedContainerId: number) {
    const collectedContainer = collect.value.collectedContainers.collection.find(item => Number(item?.id) === collectedContainerId)
    if (!collectedContainer)
      return
    const documentIndex = collectedContainer.documents.collection.findIndex(item => item?.id === documentId)
    collectedContainer?.documents.collection.splice(documentIndex, 1)
  }

  async function rateCollectNps(input: RateCollectNpsInputGraphql) {
    const { mutate } = useGqlMikro()
    const { collects } = storeToRefs(useCollectsStore())

    const { data, errors } = await mutate({
      mutation: RATE_COLLECT_NPS,
      variables: { input },
    })

    if (errors) {
      throw new Error(errors[0].message)
    }

    const cs = collects.value?.collection?.find(item => item?.id === input.collectId)
    if (cs) {
      cs.npsRating = data!.rateCollectNPS.npsRating
    }
    collect.value!.npsRating = data!.rateCollectNPS.npsRating

    return { data }
  }

  return {
    collect,
    collectLoading,
    VAT,
    totalPriceMaterialsWithoutVAT,
    totalPriceMaterialsWithVAT,
    pricingOfMaterials,
    incidents,
    incidentsPenalty,
    transportPrice,
    totalPriceIsSold,
    totalPriceNotSold,
    rateCollectNps,
    loadSingleCollect,
    addDocumentToCollectedContainer,
    removeDocumentFromCollectedContainer,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCollectStore, import.meta.hot))
}
