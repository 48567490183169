<script setup lang="ts">
/**
 * @fileName : String
 * (The name of the file)
 *
 * @fileSrc : String
 * (The source of the file)
 *
 * @fileType : String
 * (The type of the file, if not provided, it will be automatically detected)
 */
const props = defineProps({
  file: {
    type: Object,
  },
  fileName: {
    type: String,
    required: true,
  },
  fileSrc: {
    type: String,
    required: true,
  },
  fileType: {
    type: String,
    required: false,
    default: null,
  },
})

const fileType = computed((): string => {
  if (!props.fileName)
    return ''

  let fileType = props.fileType
  if (!fileType) {
    if (props.fileName.includes('.'))
      fileType = props.fileName.split('.')[props.fileName.split('.').length - 1].toLowerCase()
    else fileType = props.fileSrc.split('.')[props.fileSrc.split('.').length - 1].toLowerCase()
  }

  if (fileType.includes('pdf') || fileType === 'pdf') {
    fileType = 'pdf'
  }
  else if (
    fileType === 'xlsx'
    || fileType === 'xls'
    || fileType === 'docx'
    || fileType === 'doc'
    || fileType === 'pptx'
    || fileType === 'ppt'
  ) {
    fileType = 'office'
  }
  else if (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png' || fileType === 'webp' || fileType === 'gif') {
    fileType = 'img'
  }
  else if (fileType === 'csv') {
    fileType = 'csv'
  }
  else {
    fileType = 'other'
  }

  return fileType
})

const iframeSize = computed(() => {
  const fileReader = document.getElementById('file-reader')
  if (fileReader) {
    const iframeHeaderHeight = 64
    const width = fileReader.clientWidth
    const height = fileReader.clientHeight - iframeHeaderHeight
    return { height: `${height}px`, width: `${width}px` }
  }
  return { height: '100%', width: '100%' }
})
</script>

<template>
  <div id="file-reader" class="h-full w-full overflow-y-auto transition">
    <!-- WARNING : Remove the office reader if you are reading confidential data, prefer using an open source dependency -->
    <iframe
      v-if="fileType === 'pdf' || fileType === 'office' || fileType === 'csv'"
      :src="fileType === 'pdf' ? props.fileSrc : `https://view.officeapps.live.com/op/embed.aspx?src=${props.fileSrc}`"
      :alt="props.fileName"
      :title="props.fileName"
      :width="iframeSize.width"
      :height="iframeSize.height"
      :type="fileType === 'pdf' ? 'application/pdf' : null"
    />
    <img v-else :src="props.fileSrc" :alt="props.fileName" :title="props.fileName">
  </div>
</template>
